import React from "react"

export default function NotFound() {
    return (
        <div>
            <div className="container">
                <h1>404</h1>
            </div>
        </div>
    )
}
